import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { customAreas } from '@bridebook/toolbox/src/search-suppliers/custom-areas';
import { capitalizeFirstLetter } from '@bridebook/toolbox/src/utils/strings';
import { CustomAutocompletePrediction } from 'app-shared/lib/search/types';

const getCustomUKPredictions = (): CustomAutocompletePrediction[] =>
  customAreas.map((customArea) => {
    const prediction: CustomAutocompletePrediction = {
      area: customArea.name,
      mainText: customArea.name,
      secondaryText: customArea.subtext || 'UK',
      id: customArea.id,
      custom: true as const,
      address: {
        adminArea: [customArea.county, capitalizeFirstLetter(customArea.country)],
        name: customArea.name,
        country: CountryCodes.GB,
      },
    };
    return prediction;
  });

export const customPredictions: { [K in CountryCodes as string]?: CustomAutocompletePrediction[] } =
  {
    [CountryCodes.GB]: [
      // LIVE-17083 suggest when typing london - London first, custom London areas, Greater London in the end
      {
        area: 'London',
        mainText: 'London',
        secondaryText: 'UK',
        id: 'london-uk',
        custom: true,
        address: {
          adminArea: ['Greater London', 'England'],
          country: CountryCodes.GB,
          city: 'London',
          name: 'London',
        },
      },
      {
        area: 'Greater London',
        mainText: 'Greater London',
        secondaryText: 'Inside the M25, UK',
        id: 'greater-london-uk',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Greater London', 'England'],
          country: CountryCodes.GB,
          name: 'Greater London',
        },
      },
      ...getCustomUKPredictions(),
      {
        area: 'Sussex',
        mainText: 'Sussex',
        secondaryText: 'UK',
        id: 'sussex-uk',
        custom: true,
        address: {
          adminArea: ['Sussex', 'England'],
          country: CountryCodes.GB,
          name: 'Sussex',
        },
      },
      {
        area: 'Wales',
        mainText: 'Wales',
        secondaryText: 'UK',
        id: 'wales-uk',
        custom: true,
        address: {
          adminArea: ['Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'Wales',
        },
        replaceByMainText: true,
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        area: 'North Wales',
        mainText: 'North Wales',
        secondaryText: 'UK',
        id: 'north-wales-uk',
        custom: true,
        address: {
          adminArea: ['North Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'North Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        area: 'South Wales',
        mainText: 'South Wales',
        secondaryText: 'UK',
        id: 'south-wales-uk',
        custom: true,
        address: {
          adminArea: ['South Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'South Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        area: 'North West Wales',
        mainText: 'North West Wales',
        secondaryText: 'UK',
        id: 'north-west-wales-uk',
        custom: true,
        address: {
          adminArea: ['North West Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'North West Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        area: 'North East Wales',
        mainText: 'North East Wales',
        secondaryText: 'UK',
        id: 'north-east-wales-uk',
        custom: true,
        address: {
          adminArea: ['North East Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'North East Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        area: 'Cardiff, Vale of Glamorgan & Newport',
        mainText: 'Cardiff, Vale of Glamorgan & Newport',
        secondaryText: 'UK',
        id: 'cardiff-and-the-vale-newport-uk',
        custom: true,
        address: {
          adminArea: ['Cardiff, Vale of Glamorgan & Newport', 'Wales'],
          country: CountryCodes.GB,
          name: 'Cardiff, Vale of Glamorgan & Newport',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        area: 'Swansea, Port Talbot & Bridgend',
        mainText: 'Swansea, Port Talbot & Bridgend',
        secondaryText: 'UK',
        id: 'swansea-port-talbot-and-bridgend-uk',
        custom: true,
        address: {
          adminArea: ['Swansea, Port Talbot & Bridgend', 'Wales'],
          country: CountryCodes.GB,
          name: 'Swansea, Port Talbot & Bridgend',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        area: 'Mid Wales',
        mainText: 'Mid Wales',
        secondaryText: 'UK',
        id: 'mid-wales-uk',
        custom: true,
        address: {
          adminArea: ['Mid Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'Mid Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        area: 'West Wales',
        mainText: 'West Wales',
        secondaryText: 'UK',
        id: 'west-wales-uk',
        custom: true,
        address: {
          adminArea: ['West Wales', 'Wales'],
          country: CountryCodes.GB,
          name: 'West Wales',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        area: 'The Valleys',
        mainText: 'The Valleys',
        secondaryText: 'UK',
        id: 'the-valleys-uk',
        custom: true,
        address: {
          adminArea: ['The Valleys', 'Wales'],
          country: CountryCodes.GB,
          name: 'The Valleys',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['wales'],
        },
      },
      {
        area: 'Edinburgh',
        mainText: 'Edinburgh',
        secondaryText: 'City, UK',
        id: 'edinburgh-uk',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['City of Edinburgh', 'Scotland'],
          country: CountryCodes.GB,
          name: 'Edinburgh',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['edinburgh'],
        },
      },
      {
        area: 'City of Edinburgh',
        mainText: 'City of Edinburgh',
        secondaryText: 'County, UK',
        id: 'city-of-edinburgh-uk',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['City of Edinburgh', 'Scotland'],
          country: CountryCodes.GB,
          name: 'City of Edinburgh',
        },
        matchingRule: {
          minLength: 2,
          keywords: ['edinburgh'],
        },
      },
    ],
    [CountryCodes.DE]: [
      {
        area: 'berlin--berlin--de',
        mainText: 'Berlin',
        secondaryText: 'Berlin, Bundesland',
        id: 'berlin-bundesland-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Berlin', 'Berlin'],
          country: CountryCodes.DE,
          city: 'Berlin',
          name: 'Berlin',
        },
      },
      {
        area: 'berlin-mitte--berlin--de',
        mainText: 'Berlin-Mitte',
        secondaryText: 'Berlin, Stadt',
        id: 'berlin-stadt-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Berlin-Mitte', 'Berlin'],
          country: CountryCodes.DE,
          city: 'Berlin-Mitte',
          name: 'Berlin',
        },
      },
      {
        area: 'hamburg--hamburg--de',
        mainText: 'Hamburg',
        secondaryText: 'Hamburg, Bundesland',
        id: 'hamburg-bundesland-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Hamburg', 'Hamburg'],
          country: CountryCodes.DE,
          city: 'Hamburg',
          name: 'Hamburg',
        },
      },
      {
        area: 'hamburg-mitte--hamburg--de',
        mainText: 'Hamburg-Mitte',
        secondaryText: 'Hamburg, Stadt',
        id: 'hamburg-stadt-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Hamburg-Mitte', 'Hamburg'],
          country: CountryCodes.DE,
          city: 'Hamburg-Mitte',
          name: 'Hamburg',
        },
      },
      {
        area: 'bremen--bremen--de',
        mainText: 'Bremen',
        secondaryText: 'Bremen, Bundesland',
        id: 'bremen-bundesland-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Bremen', 'Bremen'],
          country: CountryCodes.DE,
          city: 'Bremen',
          name: 'Bremen',
        },
      },
      {
        area: 'bremen-mitte--bremen--de',
        mainText: 'Bremen-Mitte',
        secondaryText: 'Bremen, Stadt',
        id: 'bremen-stadt-de',
        custom: true,
        replaceByMainText: true,
        address: {
          adminArea: ['Bremen-Mitte', 'Bremen'],
          country: CountryCodes.DE,
          city: 'Bremen-Mitte',
          name: 'Bremen',
        },
      },
    ],
  };

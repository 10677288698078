import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useMemo } from 'react';
import { Users } from '@bridebook/models';
import { getVenueMatchesRange } from '@bridebook/toolbox';
import { getCurrentUser } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import gazetteer, { Market } from '@bridebook/toolbox/src/gazetteer';
import { countriesWithForcedCuratedListSearch } from '@bridebook/toolbox/src/i18n/features';
import { getWeddingDateRange } from '@bridebook/toolbox/src/search-suppliers/get-wedding-date-range';
import { toMillis } from '@bridebook/toolbox/src/time-utils/time-utils';
import { UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { useQuery } from '@tanstack/react-query';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { ISearchPageQuery, SortOptionValue } from 'app-shared/lib/search/types';
import { createFullQuery } from 'app-shared/lib/search/utils';
import { isWhitelistedSupplierLocale } from 'app-shared/lib/supplier/utils/is-whitelisted-supplier-locale';
import { fetchSearchPromise } from 'lib/search/actions';
import { SearchQueryKey } from 'lib/search/constants';
import { useLocationData } from 'lib/search/hooks/query/use-location-data';
import { IFetchSearchPromiseArgs } from 'lib/search/types';
import { getCountryCodeFromArea } from 'lib/search/utils/get-country-code-from-area';
import { prepareEstimateWeddingPriceParamsForSearchRequest } from 'lib/search/utils/prepare-estimate-wedding-price-params-for-search-request';

export const SEARCH_QUERY_CACHE_TIME = toMillis(15, 'min');

export type ISearchParams = Omit<ISearchPageQuery, 'slug' | 'searchParams'> & {
  slug: UrlFriendlySlug;
  page: number;
  facets: string[];
  searchParams: string[];
  sort: SortOptionValue;
  market: Market;
  isCurated?: boolean;
  weddingMonths?: number[];
  weddingWeekDays?: string[];
};

export const OMIT_ENQ_MODAL_PARAMETERS = [
  'enquiryContext',
  'enquirySupplierId',
  'multiEnquiry',
  'enquiryConfirmation',
  'enquirySupplier',
];

export const useSearchParams = (): ISearchParams | undefined => {
  const router = useRouter();
  const { query, isReady } = router;
  const weddingMarket = useMarket();

  return useMemo(() => {
    if (!isReady) return undefined;
    const { page, sort } = query as ParsedUrlQuery & { sort: SortOptionValue };
    const searchParams = (query.searchParams ?? []) as string[];
    // searchParams[0] is supplier category slug
    const slug = searchParams[0]
      ? (searchParams[0].substring('wedding-'.length) as UrlFriendlySlug)
      : 'venues';
    // searchParams[1] is area
    const area = searchParams[1] || 'uk';
    const facets = searchParams.slice(2) as string[];
    const countryCodeWithFallback = getCountryCodeFromArea(area);
    const market = gazetteer.getMarketByCountry(countryCodeWithFallback);
    const isGlobalSearch = market.country !== weddingMarket.country;
    const forceIsCuratedParam =
      countriesWithForcedCuratedListSearch.includes(weddingMarket.country) && !isGlobalSearch;

    return {
      ...query,
      // clean up parameters of enq modal
      id: undefined,
      enquiryContext: undefined,
      enquirySupplierId: undefined,
      multiEnquiry: undefined,
      enquiryConfirmation: undefined,
      enquirySupplier: undefined,
      // end clean up parameters of enq modal
      slug,
      area,
      facets: facets ?? [],
      sort,
      page: page ? Number(page) : 1,
      searchParams,
      market,
      ...(forceIsCuratedParam
        ? {
            isCurated:
              router.query.isCurated === 'true' || typeof router.query.isCurated === 'undefined',
          }
        : {}),
    };
  }, [isReady, query, router.query.isCurated, weddingMarket.country]);
};

export const getDerivedParams = async (searchParams: ISearchParams) => {
  let weddingDateRange = null;

  const currentUser = await getCurrentUser();
  const derivedParams: Record<string, any> = {};

  if (currentUser) {
    const wedding = await Users._.getById(currentUser.uid).getActiveWedding();

    if (wedding) {
      // query string has only hasLateAvailability=true, but search engine has to receive couple's wedding date
      if (searchParams?.searchParams?.includes('hasLateAvailability')) {
        if (wedding?.date) {
          weddingDateRange = getWeddingDateRange(wedding.date, wedding.l10n?.country);
          derivedParams['weddingDateRange'] = weddingDateRange;
        }
      }

      // If params have weddingMonths or weddingWeekDays
      // search engine has to receive couple's wedding budget.
      if (searchParams.weddingMonths || searchParams.weddingWeekDays) {
        if (wedding?.budget) {
          derivedParams['weddingBudget'] = wedding.budget;
        } else {
          // If the couple hasn't set their budget yet, we use the average wedding cost for the market.
          derivedParams['weddingBudget'] = searchParams?.market?.config?.averageCost;
        }
      }

      // If user doesn't have location set yet, and has guests estimates,
      // then return filters with capacityDining filter
      const estimate = wedding.guests?.estimate;
      if (!wedding.location && estimate && searchParams.slug === 'venues') {
        const capacityDining = getVenueMatchesRange(estimate);
        if (capacityDining !== '0-0') {
          derivedParams.capacityDining = capacityDining;
        }
      }
    }
  }
  return derivedParams;
};

export const useSearch = (params?: ISearchParams) => {
  const { locationData, isLocationDataFetched } = useLocationData({
    slug: params?.slug,
    area: params?.area,
    market: params?.market,
  });

  const { data: searchData, isLoading: isSearchDataLoading } = useQuery(
    [SearchQueryKey.SearchData, params],
    async ({ signal }) => {
      if (params === undefined) return;
      const processedParams = prepareEstimateWeddingPriceParamsForSearchRequest(params);
      const derivedParams = await getDerivedParams(processedParams);
      const fullQuery = createFullQuery(processedParams);
      const request: IFetchSearchPromiseArgs = {
        ...fullQuery,
        ...derivedParams,
        page: processedParams.page,
        isLoggedIn: true,
        locationData,
        countryCode: processedParams.market.country,
      };

      const result = {
        fullQuery,
        params,
        request,
        response: await fetchSearchPromise(request, signal),
        locationData: locationData ?? null,
      };

      const localeWhitelisted = isWhitelistedSupplierLocale({
        supplierCountry: result.response?.fields?.addressComponents?.country,
        market: params.market,
        isLoggedIn: true,
      });

      if (
        (result.response.statusCode && result.response.statusCode !== 200) ||
        !localeWhitelisted ||
        result.response?.message === 'Internal server error'
      ) {
        throw new Error();
      }

      return result;
    },
    {
      cacheTime: SEARCH_QUERY_CACHE_TIME,
      staleTime: SEARCH_QUERY_CACHE_TIME,
      enabled: isLocationDataFetched && !!params,
    },
  );

  return {
    data: params ? searchData : undefined,
    isLoading: isSearchDataLoading || !isLocationDataFetched,
  };
};
